/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checked_2': {
    width: 17,
    height: 9,
    viewBox: '0 0 17 9',
    data: '<path pid="0" d="M0 5.251L4.087 9l1.031-.952-4.08-3.742L0 5.25zM15.962 0L8.226 7.102 5.184 4.306l-1.046.945L8.226 9 17 .952 15.962 0zm-3.1.952L11.83 0 7.188 4.259l1.038.945L12.86.952z" _fill="#8261FC"/>'
  }
})
